import React from "react";

import Home from "./Home";
import Error from "./Error";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route component={Error} />
      </Switch>
    </Router>
  );
};

export default App;
