import React from "react";
import logo from "./logo.svg";
import Amplify from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: "XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab",
    // REQUIRED - Amazon Cognito Region
    region: "eu-west-1",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "XX-XXXX-X_abcd1234",
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: "XX-XXXX-X_abcd1234"
  }
});

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
};

const federated = {
  google_client_id: "SOME_NUMBER_HERE.apps.googleusercontent.com"
};

export default withAuthenticator(App, true, [], federated);
